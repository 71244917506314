import { useEffect, useMemo, useState } from 'react';

import { Container, Row, Button } from '@geberit/gdds';

// types
import type { Tile } from './tleb1-tiles-props';
import type { ChannelMainVideoProps } from '../video-channel/types';
import type { Items, Sizes } from './types';

// styles
import styles from './tiles.module.scss';

// components
import Tleb1Tiles from './tleb1-tiles';
import { SectionClosingLine } from 'components/ContentElementsGdds/Common/section-closing-line';
import VideoLightbox from 'components/lightbox/video-lightbox';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';
import { Headline } from '../headline/headline';

// utils
import { isEmpty } from 'utils/is-empty';
import { clickTileLink } from 'components/ContentElements/teaser/tracking-actions';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useTracking } from 'utils/hooks/useTracking';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useNord } from 'utils/hooks/use-nord';
import { carouselGridSize, gridSize } from './sizes';
import { useIsDesktop, useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { useSectionId } from 'utils/hooks/use-section-id';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { useXy } from 'utils/hooks/use-xy';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

type ColorVariant = 'bg-white' | 'bg-porcelain' | 'bg-wave' | 'bg-sky' | 'bg-norway' | 'bg-stone';

export type GddsProductiveTilesProps = {
  border: string;
  amount: number;
  aspectRatio: string;
  carousel: boolean;
  title: string;
  type: string;
  catalogName: string;
  titleInAnchor: boolean;
  sizes: Sizes;
  isSectionReference: boolean;
  anchor: string;
  subtitle: string;
  text: string;
  previewId: string;
  colorVariant: ColorVariant;
  isInSubscription: boolean;
  items: Items[];
  showLoadMoreButton: boolean;
  contentIndex?: number;
  contentLength?: number;
  background?: string;
  backgroundColor?: string;
  autoplay?: boolean;
  isChild?: boolean;
};

export function GddsProductiveTiles(props: Readonly<GddsProductiveTilesProps>) {
  const {
    items = [],
    background = '',
    backgroundColor = '',
    title,
    subtitle,
    contentIndex,
    contentLength,
    text,
    carousel,
    type,
    sizes,
    border,
    colorVariant,
    anchor,
    titleInAnchor,
    aspectRatio,
    showLoadMoreButton,
    autoplay = false,
    isChild = false,
  } = props;

  const isXy = useXy();
  const isLargeDesktop = useIsDesktop({ gdds: true });
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const track = useTracking();
  const [modalOpen, setModalOpen] = useState<false | Partial<ChannelMainVideoProps>>(false);
  const isNord = useNord();
  const isContactSection = type === 'contact_tiles';
  const loadMoreText = useTranslationByKey('web20_downloadcenter_more_results');
  const backgroundParam = background || backgroundColor || 'bg-white';
  const tileBackground = backgroundParam === 'white' ? 'light' : 'white';
  const sectionId = useSectionId(title ?? '', anchor ?? '', Boolean(titleInAnchor), contentIndex);

  const loadableTiles = useMemo(() => {
    let tileSize: number | undefined;
    if (isMobile) {
      tileSize = sizes?.mobile;
    } else if (isTablet) {
      tileSize = sizes?.tablet;
    } else tileSize = sizes?.desktop;

    return tileSize;
  }, [isMobile, isTablet, sizes]);

  const initTiles = (items, carousel, showmore, loadableTiles) => {
    if (!isEmpty(items)) {
      return !carousel && showmore ? items.slice(0, loadableTiles) : items;
    }
    return [];
  };

  const [tiles, setTiles] = useState(() =>
    initTiles(items, carousel, showLoadMoreButton, loadableTiles),
  );

  useEffect(() => {
    setTiles(initTiles(items, carousel, showLoadMoreButton, loadableTiles));
  }, [items, carousel, showLoadMoreButton, loadableTiles]);

  const trackLink = (item: Tile) => {
    track.trackEvent(clickTileLink(window.location.href, item.link?.target));
  };

  const toggleVideoModal = (video) => {
    setModalOpen(video);
  };

  const isLastSection = (contentLength ?? 1) - 1 === contentIndex;

  if (isEmpty(items)) return null;

  const gridWidth =
    isLargeDesktop && carousel && tiles.length > 3
      ? carouselGridSize['NORD']
      : gridSizes[gridSize['NORD']];

  return (
    <>
      <section
        className={classNameBuilder(
          styles.productiveWrapper,
          isLastSection && styles.isLast,
          colorVariant ? styles[colorVariant] : '',
          !border ? styles.border : '',
        )}
        id={sectionId}
      >
        <InlineEdit
          previewId={(function () {
            if (isNord) {
              return '#st_tiles';
            }
            if (isContactSection) {
              return '#st_contacts';
            }
            return undefined;
          })()}
        >
          <Container maxContentWidth={buildSize(gridWidth)} className={styles.tilesWrapper}>
            <Headline
              title={title}
              subtitle={subtitle}
              text={text}
              tag={isChild && isXy ? Formats.h4 : Formats.h2}
              variant={Formats.h2}
              className={styles.headline}
              textClassName={styles.copyText}
              titlePreviewId="#st_title"
              subtitlePreviewId="#st_subtitle"
            />
          </Container>
          <Container maxContentWidth={buildSize(gridWidth)} className={styles.tilesWrapper}>
            {!isEmpty(tiles) && (
              <Row>
                <Tleb1Tiles
                  tiles={tiles}
                  isMobile={isMobile}
                  toggleVideoModal={toggleVideoModal}
                  trackLink={trackLink}
                  isContactSection={isContactSection}
                  tileBackground={tileBackground}
                  carousel={Boolean(carousel)}
                  aspectRatio={aspectRatio}
                  colorVariant={colorVariant}
                  autoplay={autoplay}
                  isChild={isChild}
                />
              </Row>
            )}
            {!carousel && showLoadMoreButton && tiles?.length < items?.length && (
              <Row className={styles.buttonRow}>
                <Button
                  symbol="Reload"
                  className={styles.loadmoreIcon}
                  onClick={() => {
                    setTiles(items.slice(0, tiles?.length + loadableTiles));
                  }}
                >
                  {loadMoreText}
                </Button>
              </Row>
            )}
          </Container>
        </InlineEdit>
        {!border && <SectionClosingLine />}
      </section>
      {modalOpen && (
        <VideoLightbox
          onClose={toggleVideoModal}
          videoTitle={modalOpen?.title}
          videoId={modalOpen.video?.videoId ?? ''}
          isYoutubeVideo={modalOpen.video?.type === 'youtube'}
        />
      )}
    </>
  );
}
