import { useEffect, useMemo, useState } from 'react';
import { Container, Col, Row, Button } from '@geberit/gdds';

// types
import type { Tile } from './tleb1-tiles-props';
import type { Items, Sizes } from './types';
import type { ChannelMainVideoProps } from '../video-channel/types';

// styles
import styles from './tiles.module.scss';

// components
import Tleb1Tiles from './tleb1-tiles';
import { Headline, Formats, getFontWeight } from 'components/ContentElements/Headline';
import VideoLightbox from 'components/lightbox/video-lightbox';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { isEmpty } from 'utils/is-empty';
import { clickTileLink } from 'components/ContentElements/teaser/tracking-actions';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useTracking } from 'utils/hooks/useTracking';
import { buildSize, gridSizes } from 'utils/gridSize';
import { carouselGridSize, gridSize } from './sizes';
import { useIsDesktop, useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { useThemeName } from 'utils/hooks/use-theme';
import { useTranslationByKey } from 'utils/hooks/use-translations';

export type GroupProductiveTilesProps = {
  sizes: Sizes;
  background: string;
  selectMode: string;
  subtitle: string;
  showmore: boolean;
  carousel: boolean;
  emphasize: string;
  previewId: string;
  title: string;
  type: string;
  isInSubscription: boolean;
  items: Items[];
  contentIndex?: number;
  contentLength?: number;
};

export function GroupProductiveTiles(props: Readonly<GroupProductiveTilesProps>) {
  const {
    items = [],
    background = '',
    title,
    subtitle,
    emphasize,
    contentIndex,
    contentLength,
    carousel,
    showmore,
    type,
    sizes,
    selectMode,
  } = props;

  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const track = useTracking();
  const [modalOpen, setModalOpen] = useState<false | Partial<ChannelMainVideoProps>>(false);
  const themeName = useThemeName();
  const isContactSection = type === 'contact_tiles';
  const loadMoreText = useTranslationByKey('web20_downloadcenter_more_results');
  const tileBackground = background === 'white' ? 'light' : 'white';

  const loadableTiles = useMemo(() => {
    let tileSize: number | undefined;
    if (isMobile) {
      tileSize = sizes?.mobile;
    } else if (isTablet) {
      tileSize = sizes?.tablet;
    } else tileSize = sizes?.desktop;

    return tileSize;
  }, [isMobile, isTablet, sizes]);

  const initTiles = (items, carousel, showmore, loadableTiles) => {
    if (!isEmpty(items)) {
      return !carousel && showmore ? items.slice(0, loadableTiles) : items;
    }
    return [];
  };

  const [tiles, setTiles] = useState(() => initTiles(items, carousel, showmore, loadableTiles));

  useEffect(() => {
    setTiles(initTiles(items, carousel, showmore, loadableTiles));
  }, [items, carousel, showmore, loadableTiles]);

  const trackLink = (item: Tile) => {
    track.trackEvent(clickTileLink(window.location.href, item.link?.target));
  };

  const toggleVideoModal = (video) => {
    setModalOpen(video);
  };

  const isLastSection = (contentLength ?? 1) - 1 === contentIndex;

  if (isEmpty(items) || (selectMode === 'topics' && items.length < 2)) return null;

  const gridWidth =
    isDesktop && carousel && tiles.length > 3
      ? carouselGridSize['GDDS']
      : gridSizes[gridSize['GDDS']];

  return (
    <>
      <div
        className={classNameBuilder(
          styles.productiveWrapper,
          styles[background],
          isLastSection && styles.isLast,
        )}
      >
        {(title || subtitle) && (
          <Container maxContentWidth={buildSize(gridSizes.full)}>
            <Row>
              <Col size={[4, 8, 12]}>
                {(title || subtitle) && (
                  <Headline
                    format={Formats.h1}
                    title={title}
                    subtitle={subtitle}
                    tag={Formats.h2}
                    titleFontWeight={getFontWeight(emphasize === 'title', themeName, Formats.h1)}
                    subtitleFontWeight={getFontWeight(
                      emphasize === 'subtitle',
                      themeName,
                      Formats.h1,
                    )}
                    className={styles.headline}
                    titlePreviewId="#st_title"
                    subtitlePreviewId="#st_subtitle"
                  />
                )}
              </Col>
            </Row>
          </Container>
        )}
        <InlineEdit
          previewId={(function () {
            if (selectMode === 'manual') {
              return '#st_tiles';
            }
            if (isContactSection) {
              return '#st_contacts';
            }
            return undefined;
          })()}
        >
          <Container maxContentWidth={buildSize(gridWidth)} className={styles.tilesWrapper}>
            {!isEmpty(tiles) && (
              <Row>
                <Tleb1Tiles
                  tiles={tiles}
                  isMobile={isMobile}
                  toggleVideoModal={toggleVideoModal}
                  trackLink={trackLink}
                  isContactSection={isContactSection}
                  tileBackground={tileBackground}
                  carousel={carousel}
                />
              </Row>
            )}
            {!carousel && showmore && tiles?.length < items?.length && (
              <Row className={styles.buttonRow}>
                <Button
                  symbol="Reload"
                  className={styles.loadmoreIcon}
                  onClick={() => {
                    setTiles(items.slice(0, tiles?.length + loadableTiles));
                  }}
                >
                  {loadMoreText}
                </Button>
              </Row>
            )}
          </Container>
        </InlineEdit>
      </div>
      {modalOpen && (
        <VideoLightbox
          onClose={toggleVideoModal}
          videoTitle={modalOpen?.title}
          videoId={modalOpen.video?.videoId ?? ''}
          isYoutubeVideo={modalOpen.video?.type === 'youtube'}
        />
      )}
    </>
  );
}
