import { Container, Row, Col, Divider } from '@geberit/gdds';

// styles
import styles from './border.module.scss';

// utils
import { buildSize, gridSizes } from 'utils/gridSize';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useGroup } from 'utils/hooks/use-group';

interface SectionClosingLineProps {
  withBottomMargin?: boolean;
}

export function SectionClosingLine({
  withBottomMargin = false,
}: Readonly<SectionClosingLineProps>) {
  const isGroup = useGroup();

  return (
    <Container maxContentWidth={buildSize(gridSizes[isGroup ? 'full' : 'gddsFullGrid'])}>
      <Row>
        <Col
          size={[4, 8, 12]}
          className={classNameBuilder(styles.border, withBottomMargin && styles.withBottomMargin)}
        >
          <Divider alignment="horizontal" />
        </Col>
      </Row>
    </Container>
  );
}
