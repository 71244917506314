// types
import type { IContactProps } from './contact.types';

// styles
import styles from './contact.module.scss';

// components
import { CmsLink } from 'components/Link/CmsLink';
import { Translation } from 'components/Translation/Translation';

// utils
import { clickTileLink } from 'components/ContentElements/teaser/tracking-actions';
import { useTracking } from 'utils/hooks/useTracking';
import { previewId } from 'utils/preview-id';

function Contact({
  city,
  country,
  description,
  download_file: downloadFile,
  download_title: downloadTitle,
  fax,
  mail,
  name,
  phone,
  street,
  zip,
  isTile,
  isPreview,
  ...props
}: Readonly<IContactProps>) {
  const track = useTracking();

  return (
    <div {...props} className={styles.contact}>
      {/* address and name */}
      <div>
        {name && !isTile && <p {...previewId(isPreview && '#tt_name')}>{name}</p>}
        {description && <p {...previewId(isPreview && '#tt_description')}>{description}</p>}
        {street && <p {...previewId(isPreview && '#tt_street')}>{street}</p>}
        {(zip || city) && (
          <p>
            <span {...previewId(isPreview && '#tt_zip')}>{zip}</span>{' '}
            <span {...previewId(isPreview && '#tt_city')}>{city}</span>
          </p>
        )}
        {country && <p {...previewId(isPreview && '#tt_country')}>{country}</p>}
      </div>

      {/* contact data */}
      <div>
        {phone && (
          <p>
            <span className={styles.fixedWidth}>
              <Translation id="group_contact_phone" />
            </span>
            <span className={styles.linkContainer}>
              <CmsLink
                stylingType="primary"
                link={{ target: `tel:${phone}` }}
                className={styles.link}
                standardFontSize={false}
              >
                {phone}
              </CmsLink>
            </span>
          </p>
        )}
        {fax && (
          <p>
            <span className={styles.fixedWidth}>
              <Translation id="group_contact_fax" />
            </span>
            <span className={styles.linkContainer}>
              <CmsLink
                stylingType="primary"
                link={{ target: `tel:${fax}` }}
                className={styles.link}
                standardFontSize={false}
              >
                {fax}
              </CmsLink>
            </span>
          </p>
        )}
        {mail && (
          <p className={styles.email}>
            {!isTile && (
              <span className={styles.fixedWidth}>
                <Translation id="group_contact_email" />
              </span>
            )}
            <CmsLink
              stylingType="primary"
              link={{
                target: `mailto:${mail}`,
                onClick: () => {
                  track.trackEvent(clickTileLink(window.location.href, `mailto:${mail}`));
                },
              }}
              className={styles.link}
              standardFontSize={false}
            >
              {mail}
            </CmsLink>
          </p>
        )}
        {downloadTitle && downloadFile && (
          <p>
            <CmsLink
              link={{ type: 'internal_link', showArrow: true, target: downloadFile }}
              stylingType="primary"
              alignByContent="left"
              className={styles.link}
              standardFontSize={false}
            >
              {downloadTitle}
            </CmsLink>
          </p>
        )}
      </div>
    </div>
  );
}

export default Contact;
