// components
import { GroupProductiveTiles, type GroupProductiveTilesProps } from './group-productive-tiles';
import { GddsProductiveTiles, type GddsProductiveTilesProps } from './gdds-productive-tiles';

// utils
import { useGroup } from 'utils/hooks/use-group';

export function ProductiveTiles(
  props: Readonly<GroupProductiveTilesProps | GddsProductiveTilesProps>,
) {
  const isGroup = useGroup();

  if (isGroup) {
    return <GroupProductiveTiles {...(props as GroupProductiveTilesProps)} />;
  }

  return <GddsProductiveTiles {...(props as GddsProductiveTilesProps)} />;
}
